exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contact-verstuurd-tsx": () => import("./../../../src/pages/contact/verstuurd.tsx" /* webpackChunkName: "component---src-pages-contact-verstuurd-tsx" */),
  "component---src-pages-dienstverlening-tsx": () => import("./../../../src/pages/dienstverlening.tsx" /* webpackChunkName: "component---src-pages-dienstverlening-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nieuws-tsx": () => import("./../../../src/pages/nieuws.tsx" /* webpackChunkName: "component---src-pages-nieuws-tsx" */),
  "component---src-pages-portofolio-tsx": () => import("./../../../src/pages/portofolio.tsx" /* webpackChunkName: "component---src-pages-portofolio-tsx" */)
}

